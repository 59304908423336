<template>
  <v-container fluid class="grey lighten-3">

    <v-card>
      <v-card-title>
        {{ name }}
      </v-card-title>

      <v-card-text>
        <v-container fluid>

          <v-form ref="form">
            <v-text-field v-model="item.keyword" label="Keyword" required :rules="[v => !!v || 'Keyword is required']"></v-text-field>

          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="pl-5 pb-5">
        <v-btn class="mr-2" :to="{ name: 'Negative keywords' }">Back</v-btn>
        <v-btn color="primary" :disabled="!hasSEAPermissions" :loading="saving" @click="save()">Save</v-btn>

        <v-fade-transition mode="out-in">
          <v-btn color="green darken-1" text v-show="showSaved">Saved</v-btn>
        </v-fade-transition>

      </v-card-actions>

    </v-card>

  </v-container>
</template>

<script>
import auth from '@/auth'
import formsMixin from '@/mixins/forms'

export default {
  name: 'NkwDwhWhitelistDetail',

  props: {
    id: { type: Number, required: true },
  },

  data () {
    return {
      item: {
        keyword: null,
      },
      valid: false,
      loading: false,
      saving: false,
      showSaved: false,
    }
  },

  computed: {
    dataEndpoint () {
      return '/a/ext-dwh/am-whitelist-keywords/' + this.id
    },

    hasSEAPermissions () {
      if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
        return true
      }
      return false
    },

    name () {
      return 'ID #' + this.id
    },
  },

  components: {
  },

  mixins: [formsMixin],

  methods: {
    fetchData: function () {
      this.loading = true

      // reset data (to bring back deleted item fields)
      Object.assign(this.$data, this.$options.data.apply(this))

      // xfields not used at the moment: keeping param so that others can be added with & and not ?
      var myURL = this.dataEndpoint + '?xfields=keyword'

      this.$http.get(myURL).then(resp => {
        this.item = resp.data.data
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    save: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      this.saving = true

      // only send the required item fields to API
      delete this.item['id']
      delete this.item['created_at']
      delete this.item['created_by']
      delete this.item['updated_at']
      delete this.item['updated_by']

      var body = JSON.stringify(this.item)
      this.$http.put(this.dataEndpoint, body).then(resp => {
        this.showSaved = true
        this.timer = setTimeout(() => { this.showSaved = false }, 3000)
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: this.dataEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.saving = false)
    },
  },

  created: function () {
    this.fetchData()
  },
}
</script>
